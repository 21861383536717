import { cookiesConstants, getCookie, setCookie } from './cookies';
var getUniqueTrackId = function getUniqueTrackId() {
  var _crypto;
  var cryptoUuid = (_crypto = crypto) == null ? void 0 : _crypto.randomUUID == null ? void 0 : _crypto.randomUUID();
  if (cryptoUuid) {
    return cryptoUuid;
  }
  return 'PLACEHOLDER_ERROR_GENERATING';
};
var getPlaceholderTrackId = function getPlaceholderTrackId() {
  return getCookie(cookiesConstants.ML2_TID_PLACEHOLDER);
};
var generatePlaceholderTrackId = function generatePlaceholderTrackId(domain) {
  var trackId = getPlaceholderTrackId();
  if (!trackId) {
    var expires = new Date();
    expires.setHours(24);
    trackId = getUniqueTrackId();
    setCookie({
      domain: domain,
      expires: expires,
      name: cookiesConstants.ML2_TID_PLACEHOLDER,
      value: trackId
    });
  }
  return trackId;
};
export default {
  generatePlaceholderTrackId: generatePlaceholderTrackId,
  getPlaceholderTrackId: getPlaceholderTrackId
};