export default {
  ACCEPT_POLICY: 'cookie_accept_policy',
  GA_ADD_TO_CART: 'ga_cookie_add',
  GATEKEEPER_EXHIBITION: "gatekeeper_exhibition",
  ML_EXP: 'ml_exp',
  ML2_SID: "ml2_sid",
  ML2_SID_C: "ml2_sid_c",
  ML2_TID: "ml_tid",
  ML2_TID_PLACEHOLDER: "ml_tid_new",
  MLPARCEIRO: "MLPARCEIRO",
  PMD_SHAREDBAG: "pmd_sharedbag",
  REJECT_ZIPCODE_POPUP: 'cookie_reject_zipcode_popup',
  ZIPCODE: "zipcode",
  ZIPCODE_CHECKSUM: "zipcode_checksum",
  ZIPCODE_CITY: "zipcode_city",
  ZIPCODE_LATITUDE: "zipcode_latitude",
  ZIPCODE_LONGITUDE: "zipcode_longitude",
  ZIPCODE_STATE: "zipcode_state"
};