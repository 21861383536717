import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _typeof from "@babel/runtime/helpers/esm/typeof";
import Cookies from 'universal-cookie';
import whereAmI from '../whereAmI';
import parseStringToBoolean from '../parseStringToBoolean';
import onlyDefinedProps from '../onlyDefinedProps';
import Logger from '../logger';
export var parseAccountDataClient = function parseAccountDataClient(data, logger) {
  var isBrowser = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : whereAmI.onClient;
  if (isBrowser) {
    try {
      if (_typeof(data) === 'object') {
        return data;
      }
      return data ? JSON.parse(decodeURIComponent(data)) : undefined;
    } catch (e) {
      logger.error("Invalid cookie ml2_sid_c = ".concat(data));
    }
  }
  return undefined;
};
export var parseLoyaltyData = function parseLoyaltyData(promoterData, ctx) {
  var _ctx$req, _window, _window$location;
  var _ref = (promoterData == null ? void 0 : promoterData.split('|')) || [],
    _ref2 = _slicedToArray(_ref, 2),
    pmdStore = _ref2[0],
    pmdPromoter = _ref2[1];
  if (!pmdStore || !pmdPromoter) return undefined;
  var location = (ctx == null ? void 0 : (_ctx$req = ctx.req) == null ? void 0 : _ctx$req.url) || ((_window = window) == null ? void 0 : (_window$location = _window.location) == null ? void 0 : _window$location.href);
  if (!location || !location.includes(pmdStore)) return undefined;
  return pmdPromoter;
};
export var parsePromoterData = function parsePromoterData(promoterData) {
  var _ref3 = (promoterData == null ? void 0 : promoterData.split('|')) || [],
    _ref4 = _slicedToArray(_ref3, 3),
    pmdStore = _ref4[0],
    pmdPromoter = _ref4[1],
    pmdName = _ref4[2];
  if (!pmdStore || !pmdPromoter) return undefined;
  return {
    loyalty: pmdPromoter,
    name: pmdName,
    store: pmdStore
  };
};
var useCookies = function useCookies() {
  var _ctx$req2, _ctx$req2$headers;
  var ctx = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var logger = settings.logger || Logger((settings == null ? void 0 : settings.config) || {});
  var cookies = whereAmI.onClient ? new Cookies() : new Cookies(ctx == null ? void 0 : (_ctx$req2 = ctx.req) == null ? void 0 : (_ctx$req2$headers = _ctx$req2.headers) == null ? void 0 : _ctx$req2$headers.cookie);
  var _cookies$getAll = cookies.getAll(),
    acceptPolicy = _cookies$getAll.cookie_accept_policy,
    zipCode = _cookies$getAll.zipcode,
    zipCodeChecksum = _cookies$getAll.zipcode_checksum,
    zipCodeCity = _cookies$getAll.zipcode_city,
    zipCodeState = _cookies$getAll.zipcode_state,
    zipCodeLatitude = _cookies$getAll.zipcode_latitude,
    zipCodeLongitude = _cookies$getAll.zipcode_longitude,
    partnerId = _cookies$getAll.MLPARCEIRO,
    customerId = _cookies$getAll.stwu,
    gatekeeperExhibitionRule = _cookies$getAll.gatekeeper_exhibition,
    trackId = _cookies$getAll.ml_tid,
    trackIdPlaceholder = _cookies$getAll.ml_tid_new,
    accountDataEncoded = _cookies$getAll.ml2_sid_c,
    cartId = _cookies$getAll.ml2_sid,
    clientId = _cookies$getAll._ga,
    promoterData = _cookies$getAll.pmd_promoter,
    promoterId = _cookies$getAll.promoter_id,
    togglePdpSellerScore = _cookies$getAll.toggle_pdp_seller_score,
    toggleSectionSharedBag = _cookies$getAll.toggle_sharedbag,
    toggleShortenWidget = _cookies$getAll.toggle_shorten_widget,
    toggleShipping = _cookies$getAll.mixer_shipping,
    toggleWishlist = _cookies$getAll.toggle_wishlist,
    searchExperiment = _cookies$getAll.search_experiment,
    magaluExperiment = _cookies$getAll.ml_exp,
    rejectZipcodePopup = _cookies$getAll.cookie_reject_zipcode_popup,
    toggleAgatha = _cookies$getAll.toggle_agatha,
    toggleAds = _cookies$getAll.toggle_ads,
    toggleNewServicePage = _cookies$getAll.toggle_new_service_page;
  var location = {
    zipCode: zipCode,
    zipCodeChecksum: zipCodeChecksum,
    zipCodeCity: zipCodeCity && decodeURIComponent(zipCodeCity),
    zipCodeLatitude: zipCodeLatitude,
    zipCodeLongitude: zipCodeLongitude,
    zipCodeState: zipCodeState
  };
  return onlyDefinedProps({
    acceptPolicy: acceptPolicy,
    accountData: parseAccountDataClient(accountDataEncoded, logger),
    cartId: cartId,
    clientId: clientId,
    customerId: customerId,
    gatekeeperExhibitionRule: gatekeeperExhibitionRule,
    location: location,
    magaluExperiment: magaluExperiment,
    partnerId: partnerId,
    pmdPromoter: parseLoyaltyData(promoterData, ctx),
    promoterData: parsePromoterData(promoterData),
    promoterId: promoterId,
    rejectZipcodePopup: rejectZipcodePopup,
    searchExperiment: searchExperiment,
    toggleAds: parseStringToBoolean(toggleAds, false),
    toggleAgatha: parseStringToBoolean(toggleAgatha, false),
    toggleNewServicePage: parseStringToBoolean(toggleNewServicePage, false),
    togglePdpSellerScore: parseStringToBoolean(togglePdpSellerScore, true),
    toggleSectionSharedBag: toggleSectionSharedBag,
    toggleShipping: toggleShipping,
    toggleShortenWidget: toggleShortenWidget,
    toggleWishlist: parseStringToBoolean(toggleWishlist, true),
    trackId: trackId,
    trackIdPlaceholder: trackIdPlaceholder
  });
};
export default useCookies;